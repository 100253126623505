import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { FdrLink } from '@hotelplan/supergraph-api';

export type TFdrOfferBreadcrumb = {
  link?: FdrLink;
  caption: string;
};

export interface IFdrOfferBreadCrumbsProps {
  breadcrumbs: Array<TFdrOfferBreadcrumb>;
  className?: string;
}

function FdrOfferBreadcrumbsContent({
  breadcrumbs,
  className,
}: IFdrOfferBreadCrumbsProps): ReactElement {
  if (!breadcrumbs?.length) return null;

  return (
    <div className={className}>
      {breadcrumbs.map(({ link, caption }, i) => {
        return (
          <React.Fragment key={`${i}_${caption}`}>
            {link ? (
              <FdrRoute link={link}>{caption}</FdrRoute>
            ) : (
              <span>{caption}</span>
            )}

            {breadcrumbs.length - 1 !== i ? ', ' : ''}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export const FdrOfferBreadcrumbs = styled(FdrOfferBreadcrumbsContent)({
  marginBottom: 0,
});
