import { useTranslation } from 'next-i18next';
import React from 'react';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { mapFeatureNameToIcon } from '@hotelplan/libs.mappers';
import Disrupter from 'components/domain/disrupter/Disrupter';
import FdrProductCardImageGallery from 'fdr/components/local/fdr-product-card/fdr-product-card-image-gallery';
import { FdrProductCardGalleryImageWrap } from 'fdr/components/local/fdr-product-card/fdr-product-card-image-gallery.styles';
import { FdrProductCardWrapper } from 'fdr/components/local/fdr-product-card/fdr-product-card-wrapper';
import { FDR_TOP_HOTEL_OFFER_ID } from 'fdr/components/local/fdr-product-card/fdr-product-card.constants';
import {
  FdrProductCardWrap,
  FdrProductTravelTypeTitle,
  FdrProductCardImage,
  FdrProductCardImageWrap,
  FdrProductCardTitle,
} from 'fdr/components/local/fdr-product-card/fdr-product-card.styles';
import { IFdrProductCardProps } from 'fdr/components/local/fdr-product-card/fdr-product-card.types';
import FdrThirdProductCardFooter from 'fdr/components/local/fdr-product-card/product-card-third/fdr-product-card-third-footer';
import {
  FdrThirdTravelTypeWrap,
  FdrThirdProductCardContent,
  FdrThirdProductCardContentContainer,
  FdrThirdProductCardContentWrap,
  FdrThirdProductCardRating,
  FdrThirdProductCardSubTitle,
  FdrThirdProductFeaturesSummary,
  FdrThirdProductTravelType,
  FdrThirdTopOffer,
  FdrThirdTripAdvisorInfoWrapper,
} from './fdr-product-card-third.styles';

const FdrProductCardThird: React.FC<IFdrProductCardProps> = ({
  publicId,
  productTravelType,
  image,
  rating,
  ta,
  title,
  subTitle,
  closeButton,
  children,
  productFeatures,
  price,
  link,
  openMethod,
  onClick,
  testId,
  disrupter,
  distanceInfo,
  productType,
  className,
  transferPrice,
  transferIncluded,
  travelType,
}) => {
  const [t] = useTranslation(['common', 'srl', 'orl']);
  const { mobile } = useDeviceType();

  const topOffer = productFeatures?.filter(
    item => item.id === FDR_TOP_HOTEL_OFFER_ID
  );

  const showDistanceInfo =
    !!distanceInfo &&
    (productType === 'FdrHotel' || productType === 'FdrApartment');
  const showTransferInfo = !!transferPrice || transferIncluded;

  const isRoundtripOrCruise = ['FdrCruise', 'FdrRoundTrip'].includes(
    productType
  );

  return (
    <FdrProductCardWrapper
      link={{ ...link, openMethod: openMethod ? openMethod : link?.openMethod }}
      onClick={onClick}
    >
      {disrupter && (
        <Disrupter
          dangerouslySetInnerHTML={{ __html: disrupter }}
          className="disrupter"
          data-id="disrupter"
        />
      )}
      <FdrProductCardWrap className={className} data-id={testId}>
        <FdrThirdTravelTypeWrap>
          {productTravelType && (
            <FdrThirdProductTravelType>
              {productTravelType.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <FdrProductTravelTypeTitle>
                      <BsIcon name={item.icon} />
                      {t(item.title)}
                    </FdrProductTravelTypeTitle>
                    {productTravelType.length - 1 !== i && (
                      <div className="delimiter"> + </div>
                    )}
                  </React.Fragment>
                );
              })}
            </FdrThirdProductTravelType>
          )}
          {topOffer?.length ? (
            <FdrThirdTopOffer>
              {topOffer.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <span>
                      <BsIcon name={mapFeatureNameToIcon(item.id)} size="sm" />
                      {t(item.name)}
                    </span>
                  </React.Fragment>
                );
              })}
            </FdrThirdTopOffer>
          ) : null}
        </FdrThirdTravelTypeWrap>

        <FdrThirdProductCardContentContainer>
          <FdrThirdProductCardContent>
            {image ? (
              <>
                {typeof image === 'object' && 'pagination' in image ? (
                  <FdrProductCardGalleryImageWrap>
                    {image.images?.length > 0 && (
                      <FdrProductCardImageGallery
                        productId={publicId}
                        images={image}
                      />
                    )}
                  </FdrProductCardGalleryImageWrap>
                ) : (
                  <FdrProductCardImageWrap>
                    <FdrProductCardImage image={image} />
                    {closeButton}
                  </FdrProductCardImageWrap>
                )}
              </>
            ) : null}

            <FdrThirdProductCardContentWrap>
              <div className="cardContentText">
                {rating > 0 && (
                  <div className="rating-wrap">
                    <FdrThirdProductCardRating
                      maxRating={5}
                      rating={rating}
                      ratingContent={<BsIcon name="hotelplanbewertung" />}
                      halfWayRatingContent={
                        <BsIcon name="hotelplanbewertung-outlined" />
                      }
                    />
                  </div>
                )}
                {title && <FdrProductCardTitle>{title}</FdrProductCardTitle>}
                {subTitle && (
                  <FdrThirdProductCardSubTitle>
                    {subTitle}
                  </FdrThirdProductCardSubTitle>
                )}
                {isRoundtripOrCruise && children}
                {productFeatures?.length && !isRoundtripOrCruise ? (
                  <FdrThirdProductFeaturesSummary
                    groups={productFeatures.slice(0, 4)}
                    withName
                    successIcon={`checkmark-black`}
                  />
                ) : null}
                {ta?.rating ? (
                  <FdrThirdTripAdvisorInfoWrapper
                    rating={ta.rating || 0}
                    count={ta.reviews || 0}
                    label={t(
                      `srl:offers.${
                        mobile ? 'tripAdvisorMobile' : 'tripAdvisor'
                      }`
                    )}
                    className="card-trip-advisor"
                  />
                ) : null}
              </div>
            </FdrThirdProductCardContentWrap>
          </FdrThirdProductCardContent>
        </FdrThirdProductCardContentContainer>

        <FdrThirdProductCardFooter
          distanceInfo={distanceInfo}
          showDistanceInfo={showDistanceInfo}
          showTransferInfo={showTransferInfo}
          transferIncluded={transferIncluded}
          transferPrice={transferPrice}
          price={price}
          isRoundtripOrCruise={isRoundtripOrCruise}
          travelType={travelType}
        >
          {!isRoundtripOrCruise && children}
        </FdrThirdProductCardFooter>
      </FdrProductCardWrap>
    </FdrProductCardWrapper>
  );
};

export default FdrProductCardThird;
