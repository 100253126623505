import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const fdrSmallProductCardStyles = ({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    boxShadow: 'none',
    flexDirection: ['column', 'column'],
    '.cardContent': {
      flexDirection: ['column', 'column'],
      p: 0,
    },
    '.cardImageWrap, img': {
      height: ['147px', '188px'],
    },
    '&&& .cardImageWrap': {
      minHeight: ['147px', '188px'],
      flexBasis: ['147px', '188px'],
      borderRadius: 0,
    },
    '.cardContentWrap': {
      flexBasis: [null, 'auto'],
    },
    '&&& .cardContentText': {
      px: 3,
      pt: 3,
    },
    '.disrupter': {
      position: 'absolute',
      top: '0',
      left: '0',
      zIndex: '1',
      border: 'none',
      ...FONT_SIZE.LARGE_BOLD,
      lineHeight: [1.5, 1],
    },
    '.disrupter ~ .travelType': {
      top: ['41px', '51px'],
      zIndex: '2',
    },
    '.productFeatures': {
      py: 2,
    },
    '.travelType': {
      m: 2,
    },
  });
