import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import {
  FdrTripAdvisorInfoWrapper,
  FdrProductCardContent,
  FdrProductCardContentWrap,
  FdrProductFeaturesSummary,
  FdrProductCardSubTitle,
  FdrTravelTypeWrap,
  FdrProductCardTravelType,
  FdrTopOffer,
  FdrProductCardRating,
} from 'fdr/components/local/fdr-product-card/fdr-product-card.styles';

export const FdrThirdTripAdvisorInfoWrapper = styled(FdrTripAdvisorInfoWrapper)(
  sx2CssThemeFn({
    pt: 2,
    '.tripAdvisorInfoText': {
      fontSize: 0,
    },
  })
);

export const FdrThirdProductCardContent = styled(FdrProductCardContent)(
  sx2CssThemeFn(({ theme: { radii, colors, media, space } }) => ({
    border: 'none',
    borderRadius: radii.default,
    '.cardImageWrap': {
      borderRadius: [`${radii.default} ${radii.default} 0 0`, radii.default],
    },
    [media.tablet]: {
      padding: `0 0 ${space[3]} 0`,
      borderBottom: '1px solid',
      borderBottomColor: colors.lightGreyFirst,
      borderRadius: '0',
    },
  }))
);

export const FdrThirdProductCardContentContainer = styled.div(
  ({ theme: { space, media } }) =>
    sx2CssThemeFn({
      padding: '0',
      width: '100%',
      [media.tablet]: {
        padding: `${space[3]} ${space[3]} 0 ${space[3]}`,
      },
    })
);

export const FdrThirdProductCardContentWrap = styled(FdrProductCardContentWrap)(
  sx2CssThemeFn(({ theme: { media, space, colors } }) => ({
    padding: [`${space[3]} ${space[3]} 0 ${space[3]}`, `0 0 0 ${space[3]}`],
    '.cardContentText': {
      padding: `0 0 ${space[3]} 0`,
      borderBottom: '1px solid',
      borderBottomColor: colors.lightGreyFirst,
      [media.tablet]: {
        padding: '0',
        border: 'none',
      },
    },
  }))
);

export const FdrThirdProductFeaturesSummary = styled(FdrProductFeaturesSummary)(
  sx2CssThemeFn(({ theme: { icons, FONT_SIZE } }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat( 2, minmax(1ch, max-content))`,
    gridGap: 1,
    maxWidth: '470px',
    paddingTop: '0',
    '.featureGroupWrapper': {
      marginRight: 4,
      marginBottom: 1,
      flexDirection: 'row',
      width: 'fit-content',
      ...FONT_SIZE.SMALL,
    },
    '.featureGroupWrapper:nth-child(even)': {
      marginRight: 0,
    },
    '.feature-summary-item-icon': {
      flexShrink: 0,
    },
    '.icon': { ...icons.md, marginRight: 1 },
  }))
);

export const FdrThirdProductCardSubTitle = styled(FdrProductCardSubTitle)(
  sx2CssThemeFn({
    mb: '12px',
    color: 'darkGray',
  })
);

export const FdrThirdTravelTypeWrap = styled(FdrTravelTypeWrap)(
  sx2CssThemeFn(({ theme: { radii, space } }) => ({
    right: 'auto',
    left: 'auto',
    top: 'auto',
    margin: [2, 4],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    '.topOffer': {
      position: 'relative',
      left: 2,
      borderRadius: radii.default,
      padding: `${space[1]} 10px`,
    },
  }))
);
export const FdrThirdProductTravelType = styled(FdrProductCardTravelType)(
  sx2CssThemeFn(({ theme: { radii, space } }) => ({
    borderRadius: radii.default,
    padding: `${space[1]} 10px`,
    '.icon': {
      marginRight: '3px',
    },
    '.delimiter': {
      px: '3px',
    },
  }))
);

export const FdrThirdTopOffer = styled(FdrTopOffer)(
  sx2CssThemeFn({
    paddingLeft: '14px',
    paddingRight: 2,
  })
);

export const FdrThirdProductCardRating = styled(FdrProductCardRating)(
  ({ theme: { media } }) => ({
    [media.tablet]: {
      marginBottom: '12px',
    },
  })
);
