import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FdrProductCardImageWrap } from './fdr-product-card.styles';

export const FdrProductCardGalleryWrapper = styled.div(
  ({ theme: { space, media } }) => ({
    position: 'relative',
    '.bs-icon-swipe': {
      display: 'none',
    },
    '.slider-control-centerleft, .slider-control-centerright': {
      display: 'none',
    },
    [media.desktop]: {
      '.slider-control-centerleft, .slider-control-centerright': {
        button: {
          height: '30px',
          width: '30px',
          position: 'relative',
          borderRadius: '50%',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          transition: 'background-color 0.2s ease-in',
        },
        svg: {
          width: '12px',
          height: '12px',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          margin: 'auto',
          filter: 'none',
          color: '#3E736D',
        },
      },
      '.slider-control-centerleft': {
        marginLeft: space[2],
        svg: {
          left: '-1px',
        },
      },
      '.slider-control-centerright': {
        marginRight: space[2],
        svg: {
          right: '-1px',
        },
      },
      '&:hover': {
        '.slider-control-centerleft, .slider-control-centerright': {
          display: 'block',
        },
      },
      '.slider-control-centerleft:hover, .slider-control-centerright:hover': {
        button: {
          backgroundColor: 'rgba(255, 255, 255, 1)',
        },
      },
    },
  })
);

export const FdrProductCardGalleryImageWrap = styled(FdrProductCardImageWrap)(
  sx2CssThemeFn({
    height: ['220px', '100%'],
  })
);

export const DOT_SIZE_PX = 6;
export const DOT_MAX_COUNT = 5;

export const FdrProductCardGalleryPaginationWrapper = styled.div(
  ({ theme: { space } }) => ({
    width: `${(2 * DOT_MAX_COUNT - 1) * DOT_SIZE_PX}px`,
    position: 'absolute',
    bottom: space[2],
    left: 0,
    right: 0,
    margin: 'auto',
    overflow: 'hidden',
  })
);

export const FdrProductCardGalleryPagination = styled.div<{
  justifyContent?: string;
}>(({ justifyContent = 'center' }) => ({
  width: 'max-content',
  display: 'flex',
  justifyContent,
  gap: `${DOT_SIZE_PX}px`,
}));

export const FdrProductCardGalleryPaginationDot = styled.div({
  width: `${DOT_SIZE_PX}px`,
  minWidth: `${DOT_SIZE_PX}px`,
  height: `${DOT_SIZE_PX}px`,
  borderRadius: '50%',
  backgroundColor: 'white',
  opacity: 0.6,
  '&.active': {
    opacity: 1,
  },
});
