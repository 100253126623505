import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { formatPrice } from '@hotelplan/fdr.lib.format.format-price';
import { FdrPrice, FdrProductTravelType } from '@hotelplan/supergraph-api';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import {
  FdrProductCardContentWrap,
  FdrDistanceInfo,
} from 'fdr/components/local/fdr-product-card/fdr-product-card.styles';

const FdrThirdProductCardFooterWrapper = styled.div(
  ({ theme: { colors, space, FONT_SIZE } }) =>
    sx2CssThemeFn({
      display: 'flex',
      borderTop: 'none',
      borderColor: colors.lightGreyFirst,
      alignItems: 'center',
      marginTop: 'auto',
      width: '100%',
      flexDirection: ['column', 'row'],
      justifyContent: 'space-between',
      padding: 3,
      '.-poi': {
        display: 'flex',
        color: colors.bgDarkBlueSecond,
        '.icon': {
          marginLeft: space[2],
          marginRight: space[1],
        },
      },
      '.product-price': {
        ...FONT_SIZE.XS,
      },
      '.-price': {
        ...FONT_SIZE.MEDIUM,
        fontWeight: 'bold',
        textAlign: 'right',
        marginRight: space[2],
      },
      '.-price > .-prefix': {
        ...FONT_SIZE.MEDIUM,
        fontWeight: 'normal',
        marginRight: space[1],
      },
    })
);

const FdrThirdProductCardContentWrap = styled(FdrProductCardContentWrap)(
  ({ theme: { media } }) =>
    sx2CssThemeFn({
      width: '100%',
      flex: '0 1 auto',
      alignSelf: 'flex-start',
      [media.tablet]: {
        flexBasis: 'auto',
      },
      '.cardContentText': {
        display: 'flex',
        flexDirection: 'column',
        padding: 3,
        [media.tablet]: {
          paddingLeft: 3,
          paddingRight: 3,
        },
      },
    })
);

const FdrThirdOfferButton = styled(BsButton)(
  sx2CssThemeFn({
    width: ['100%', 'auto'],
    px: '24px',
  })
);

const FdrThirdPriceWrapper = styled.div<{ isRoundtripOrCruise: boolean }>(
  ({ isRoundtripOrCruise }) =>
    sx2CssThemeFn({
      variant: 'link.priceLink',
      display: 'flex',
      letterSpacing: [null, '1.5'],
      p: [null, null],
      py: [null, 0],
      px: [0, 0],
      pt: [isRoundtripOrCruise ? 0 : 3, null],
      ml: [0, 2],
      alignItems: ['center', isRoundtripOrCruise ? 'center' : 'flex-end'],
      justifyContent: 'flex-start',
      width: ['100%', `fit-content`],
      whiteSpace: 'nowrap',
      alignSelf: [null, 'flex-end'],
      flexDirection: ['column', isRoundtripOrCruise ? 'row' : 'column'],
      backgroundColor: 'white',
    })
);

const FdrThirdPricePerPerson = styled.span<{ isColumnPrice: boolean }>(
  ({ isColumnPrice, theme: { FONT_SIZE } }) =>
    sx2CssThemeFn({
      ...FONT_SIZE.XS,
      pr: [0, isColumnPrice ? 0 : 1],
      '.per-person': {
        ...FONT_SIZE.MEDIUM,
      },
    })
);

const FdrThirdPriceInner = styled.div<{
  isRoundtripOrCruise: boolean;
  isColumnPrice: boolean;
}>(({ isRoundtripOrCruise, isColumnPrice }) =>
  sx2CssThemeFn({
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: [null, isColumnPrice && 'column'],
    justifyContent: 'flex-end',
    mr: [0, isRoundtripOrCruise ? 3 : 0],
    pb: [2, isRoundtripOrCruise ? 0 : 2],
    color: 'black',
  })
);

const FdrThirdTransferWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    whiteSpace: 'pre-wrap',
    alignItems: 'center',
    pt: 1,
    '.icon': {
      marginRight: 2,
      flexShrink: 0,
    },
    '.TransferInfoWrapper': {
      display: 'flex',
      whiteSpace: 'pre-wrap',
    },
  })
);

interface IFdrThirdProductCardFooterProps extends React.PropsWithChildren<{}> {
  distanceInfo?: number;
  transferPrice?: FdrPrice | null;
  transferIncluded?: boolean;
  showDistanceInfo: boolean;
  showTransferInfo: boolean;
  isRoundtripOrCruise: boolean;
  price?: FdrPrice | null;
  travelType: FdrProductTravelType;
}

const FdrThirdProductCardFooter: React.FC<IFdrThirdProductCardFooterProps> = ({
  children,
  distanceInfo,
  showDistanceInfo,
  showTransferInfo,
  transferPrice,
  transferIncluded,
  price,
  isRoundtripOrCruise,
  travelType,
}) => {
  const [t] = useTranslation(['common', 'srl', 'orl']);
  const isColumnPrice =
    travelType === FdrProductTravelType.Package && !isRoundtripOrCruise;

  return (
    <FdrThirdProductCardFooterWrapper
      className="card-footer card-row"
      data-id="product-footer"
    >
      <FdrThirdProductCardContentWrap>
        {children}
        {showDistanceInfo || showTransferInfo ? (
          <FdrThirdTransferWrapper>
            <BsIcon name="transfer" />
            <p>
              {showDistanceInfo ? (
                <FdrDistanceInfo>
                  {`${distanceInfo} km ${t('distance.secondary.info')}`}
                </FdrDistanceInfo>
              ) : null}
              {showTransferInfo && (
                <FdrDistanceInfo>
                  {showTransferInfo ? ' | ' : ''}
                  <span className="transfer">
                    {transferIncluded
                      ? t('orl:offer.transferIncluded')
                      : t('orl:offer.transfer')}
                  </span>
                  {!transferIncluded ? (
                    <span className="price">
                      {' '}
                      {`${t('common:priceFrom')} ${formatPrice(
                        transferPrice
                      )} ${t('common:transfer.perPerson')}`}
                    </span>
                  ) : null}
                </FdrDistanceInfo>
              )}
            </p>
          </FdrThirdTransferWrapper>
        ) : null}
      </FdrThirdProductCardContentWrap>
      <FdrThirdPriceWrapper isRoundtripOrCruise={isRoundtripOrCruise}>
        <FdrThirdPriceInner
          isRoundtripOrCruise={isRoundtripOrCruise}
          isColumnPrice={isColumnPrice}
        >
          <FdrThirdPricePerPerson
            data-id="offer-price-per-person"
            isColumnPrice={isColumnPrice}
          >
            <span className="per-person">
              {t('common:perPerson.secondary.from')}
            </span>{' '}
            {formatPrice(price)}
          </FdrThirdPricePerPerson>
        </FdrThirdPriceInner>
        <FdrThirdOfferButton>{t('orl:toOffers')}</FdrThirdOfferButton>
      </FdrThirdPriceWrapper>
    </FdrThirdProductCardFooterWrapper>
  );
};

export default FdrThirdProductCardFooter;
