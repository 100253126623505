import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { fdrSmallProductCardStyles } from 'fdr/components/local/fdr-page-components/recommendations/fdr-recommendations.styles';
import { FdrProductCard } from 'fdr/components/local/fdr-product-card/fdr-product-card';
import FdrFavourableContainer from 'fdr/components/local/fdr-wishlist/fdr-favourable-container';

export const FdrRecommendationProductCard = styled(FdrProductCard)<{
  isSingle?: boolean;
  isDisrupter?: boolean;
}>(({ isSingle, isDisrupter, theme: { colors } }) =>
  isSingle
    ? sx2CssThemeFn({
        '.cardImageWrap': {
          flexBasis: [null, '50%'],
          height: [null, isDisrupter ? '355px' : '410px'],
          borderRadius: '4px 4px 0 0',
          borderTop: ['none', isDisrupter ? '1px solid' : 'none'],
          borderColor: [null, colors.lightGreyFirst],
        },
        '.travelType': {
          margin: 2,
        },
        '.disrupter': {
          border: 'none',
        },
        '.cardContentWrap': {
          p: 0,
          paddingTop: [0, 3],
        },
        '.card-rows': {
          '.cardContent': {
            borderRadius: '4px',
            p: 0,
          },
        },

        '.cardContentText': {
          height: '100%',
        },
        '.card-footer': {
          px: [0, 3],
        },
      })
    : fdrSmallProductCardStyles
);

export const FdrRecommendationOfferInfo = styled.span(
  ({ theme: { space, FONT_SIZE } }) => ({
    ...FONT_SIZE.MEDIUM,
    marginBottom: space[1],
  })
);

export const FdrFromTo = styled.span(({ theme: { FONT_SIZE } }) => ({
  ...FONT_SIZE.SMALL,
}));

export const FdrProductFavContainer = styled(FdrFavourableContainer)<{
  isSingle?: boolean;
  isDisrupter?: boolean;
}>(({ theme: { media }, isSingle, isDisrupter }) => ({
  height: '100%',
  '.wishlist-icon': {
    zIndex: 1,
    right: '15px',
    position: 'absolute',
    top: isDisrupter && isSingle ? '200px' : '160px',
    height: '28px',
    [media.tablet]: {
      top: !isSingle ? '200px' : isDisrupter ? '65px' : '15px',
    },
  },
}));
