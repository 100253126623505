import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrImagesResponseFragmentDoc } from 'fdr/schemas/fragment/image/fdr-images-response.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrProductImagesPaginatedQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  pagination: Types.FdrPaginationInput;
  productImageCriteria: Array<Types.FdrImageCriteria> | Types.FdrImageCriteria;
}>;

export type FdrProductImagesPaginatedQuery = {
  __typename?: 'Query';
  fdrProduct?:
    | {
        __typename: 'FdrCruise';
        id: string;
        imagesPaginated: {
          __typename: 'FdrImagesResponse';
          images?: Array<{
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          }> | null;
          pagination: {
            __typename?: 'FdrPagination';
            total: number;
            page: number;
            perPage: number;
          };
        };
      }
    | {
        __typename: 'FdrHotel';
        id: string;
        imagesPaginated: {
          __typename: 'FdrImagesResponse';
          images?: Array<{
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          }> | null;
          pagination: {
            __typename?: 'FdrPagination';
            total: number;
            page: number;
            perPage: number;
          };
        };
      }
    | {
        __typename: 'FdrRoundTrip';
        id: string;
        imagesPaginated: {
          __typename: 'FdrImagesResponse';
          images?: Array<{
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            desaturated?: boolean | null;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          }> | null;
          pagination: {
            __typename?: 'FdrPagination';
            total: number;
            page: number;
            perPage: number;
          };
        };
      }
    | { __typename: 'FdrAdventureTravel'; id: string }
    | null;
};

export const FdrProductImagesPaginatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrProductImagesPaginated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrPaginationInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productImageCriteria' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'FdrImageCriteria' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrProduct' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'publicId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrHotel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imagesPaginated' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'input' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pagination' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'pagination' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrImagesResponse',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrRoundTrip' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imagesPaginated' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'input' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pagination' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'pagination' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrImagesResponse',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrCruise' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imagesPaginated' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'input' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'pagination' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'pagination' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrImagesResponse',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrImagesResponseFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrProductImagesPaginatedQuery__
 *
 * To run a query within a React component, call `useFdrProductImagesPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrProductImagesPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrProductImagesPaginatedQuery({
 *   variables: {
 *      id: // value for 'id'
 *      pagination: // value for 'pagination'
 *      productImageCriteria: // value for 'productImageCriteria'
 *   },
 * });
 */
export function useFdrProductImagesPaginatedQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrProductImagesPaginatedQuery,
    FdrProductImagesPaginatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrProductImagesPaginatedQuery,
    FdrProductImagesPaginatedQueryVariables
  >(FdrProductImagesPaginatedDocument, options);
}
export function useFdrProductImagesPaginatedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrProductImagesPaginatedQuery,
    FdrProductImagesPaginatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrProductImagesPaginatedQuery,
    FdrProductImagesPaginatedQueryVariables
  >(FdrProductImagesPaginatedDocument, options);
}
export type FdrProductImagesPaginatedQueryHookResult = ReturnType<
  typeof useFdrProductImagesPaginatedQuery
>;
export type FdrProductImagesPaginatedLazyQueryHookResult = ReturnType<
  typeof useFdrProductImagesPaginatedLazyQuery
>;
export type FdrProductImagesPaginatedQueryResult = Apollo.QueryResult<
  FdrProductImagesPaginatedQuery,
  FdrProductImagesPaginatedQueryVariables
>;
