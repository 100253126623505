import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { FdrImage, FdrLinkType } from '@hotelplan/supergraph-api';
import { fdrSrlProductImageCriteria } from 'fdr/components/util/criterias/srl-product-image.dmc';

const PLACEHOLDER_IMAGE_URL = '/images/spinner-mini-gallery.svg';

export const useImagePlaceholder = (): FdrImage => {
  const [t] = useTranslation();

  return useMemo(
    () => ({
      alt: t('loading'),
      __typename: 'FdrImage',
      rawUrl: PLACEHOLDER_IMAGE_URL,
      resized:
        fdrSrlProductImageCriteria?.map(i => ({
          id: i.id,
          __typename: 'FdrResizedImage',
          link: {
            __typename: 'FdrLink',
            openMethod: null,
            type: FdrLinkType.Internal,
            url: PLACEHOLDER_IMAGE_URL,
          },
        })) || [],
    }),
    [fdrSrlProductImageCriteria]
  );
};
