import { FC, useCallback, useRef, useState } from 'react';
import { FdrMediaGallery } from '@hotelplan/fdr.regular.fusion.fdr-media-gallery';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useLazyFetchingPagination } from '@hotelplan/libs.hooks';
import { FdrImage } from '@hotelplan/supergraph-api';
import { fdrSrlProductImageCriteria } from 'fdr/components/util/criterias/srl-product-image.dmc';
import { useFdrProductImagesPaginatedLazyQuery } from 'fdr/schemas/query/image/fdr-product-images-paginated.generated';
import {
  DOT_MAX_COUNT,
  DOT_SIZE_PX,
  FdrProductCardGalleryPagination,
  FdrProductCardGalleryPaginationDot,
  FdrProductCardGalleryPaginationWrapper,
  FdrProductCardGalleryWrapper,
} from './fdr-product-card-image-gallery.styles';
import { useImagePlaceholder } from './fdr-product-card-image-gallery.utils';

const IMAGES_PER_LOAD = 3;
const SLIDER_HEIGHT_DESKTOP = 211;
const SLIDER_HEIGHT_MOBILE = 220;
const PAGINATION_ICON_LEFT = 'chevron-left-2';
const PAGINATION_ICON_RIGHT = 'chevron-right-2';

export interface IFdrProductCardImageGalleryProps {
  productId: string;
  images: { images?: Omit<FdrImage, 'id'>[]; pagination: { total: number } };
}

const FdrProductCardImageGallery: FC<IFdrProductCardImageGalleryProps> = ({
  productId,
  images,
}) => {
  const { mobile } = useDeviceType();
  const total = images?.pagination.total || 0;
  const [slideIndex, setSlideIndex] = useState<number>(0);

  const [query] = useFdrProductImagesPaginatedLazyQuery();

  const fetchItems = useCallback(
    async (page: number, perPage: number) => {
      const response = await query({
        variables: {
          id: productId,
          pagination: {
            page,
            perPage,
          },
          productImageCriteria: fdrSrlProductImageCriteria,
        },
      });

      const product = response?.data?.fdrProduct;

      if (
        (product?.__typename === 'FdrHotel' ||
          product?.__typename === 'FdrCruise' ||
          product?.__typename === 'FdrRoundTrip') &&
        product.imagesPaginated.images?.length
      ) {
        return {
          items: product.imagesPaginated.images,
          pagination: product.imagesPaginated.pagination,
        };
      }

      return {
        items: [],
        pagination: {
          total: 0,
          page: 0,
          perPage: 0,
        },
      };
    },
    [query, productId]
  );

  const {
    items: paginatedImages,
    setPage,
    fetchPage,
  } = useLazyFetchingPagination(images?.images || [], {
    page: 0,
    perPage: IMAGES_PER_LOAD,
    total,
    fetchItems,
  });

  const imagePlaceholder = useImagePlaceholder();
  const imagesWithPlaceholders = Array(total || 0).fill(imagePlaceholder);

  paginatedImages.forEach((image, index) => {
    if (image) {
      imagesWithPlaceholders[index] = image;
    }
  });

  const paginationWrapperRef = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);

  const onChangeSlide = useCallback(
    (_, index: number) => {
      setSlideIndex(prevIndex => {
        setPage(Math.floor(index / IMAGES_PER_LOAD));

        const isFromFirstToLast = prevIndex === 0 && index === total - 1;
        const isFromLastToFirst = prevIndex === total - 1 && index === 0;

        // preloading slides
        if ((index > prevIndex && !isFromFirstToLast) || isFromLastToFirst) {
          // sliding right
          fetchPage(
            Math.floor((index + 1 < total ? index + 1 : 0) / IMAGES_PER_LOAD)
          );
        } else {
          //sliding left
          fetchPage(
            Math.floor(
              (index - 1 > 0 ? index - 1 : total - 1) / IMAGES_PER_LOAD
            )
          );
        }

        const lastNonScrollableIndexFromTheLeft = Math.floor(DOT_MAX_COUNT / 2);

        // animating dots
        if (index > lastNonScrollableIndexFromTheLeft) {
          paginationWrapperRef.current?.scrollTo({
            left: 2 * (index - lastNonScrollableIndexFromTheLeft) * DOT_SIZE_PX,
            ...(!isFromFirstToLast && {
              behavior: 'smooth',
            }),
          });
        } else {
          paginationWrapperRef.current?.scrollTo({
            left: 0,
            ...(!isFromLastToFirst && {
              behavior: 'smooth',
            }),
          });
        }

        return index;
      });
    },
    [total]
  );

  return (
    <FdrProductCardGalleryWrapper>
      <FdrMediaGallery
        isShowIndex={false}
        hasPreview={false}
        items={imagesWithPlaceholders}
        initialSlideHeight={
          mobile ? SLIDER_HEIGHT_MOBILE : SLIDER_HEIGHT_DESKTOP
        }
        onChangeSlide={onChangeSlide}
        leftArrowIcon={PAGINATION_ICON_LEFT}
        rightArrowIcon={PAGINATION_ICON_RIGHT}
      />
      {imagesWithPlaceholders?.length > 0 && (
        <FdrProductCardGalleryPaginationWrapper ref={paginationWrapperRef}>
          <FdrProductCardGalleryPagination
            ref={paginationRef}
            justifyContent={
              paginationWrapperRef.current &&
              paginationRef.current &&
              paginationRef.current.scrollWidth >
                paginationWrapperRef.current.clientWidth
                ? 'flex-start'
                : undefined
            }
          >
            {imagesWithPlaceholders.map((image, index) => (
              <FdrProductCardGalleryPaginationDot
                key={index}
                className={index === slideIndex ? 'active' : ''}
              />
            ))}
          </FdrProductCardGalleryPagination>
        </FdrProductCardGalleryPaginationWrapper>
      )}
    </FdrProductCardGalleryWrapper>
  );
};

export default FdrProductCardImageGallery;
