import styled from 'styled-components';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';

export const FdrOfferCloseButton = styled(BsButtonWithIcon)(
  ({ theme: { shadows } }) => ({
    borderRadius: '50%',
    boxShadow: shadows.cardShadow,
    position: 'absolute',
    zIndex: 2,
    right: '13px',
    top: '10px',
    padding: '6px',
    ':after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      height: '100%',
      padding: '25px',
    },
  })
);

export const OfferTypesAndMealType = styled.div(({ theme: { FONT_SIZE } }) => ({
  ...FONT_SIZE.MEDIUM,
}));
