import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { FdrOfferDatesInformation } from 'fdr/components/local/fdr-offer/fdr-offer-dates-information';
import { IFdrOfferItemProps } from 'fdr/components/local/fdr-offer/fdr-offers.types';
import { FdrFromTo } from 'fdr/components/local/fdr-page-components/recommendations/product/fdr-product-recommendation.styles';
import { FdrProductCard } from 'fdr/components/local/fdr-product-card/fdr-product-card';
import { FdrOfferBreadcrumbs } from './fdr-offer-breadcrumbs';
import {
  FdrOfferCloseButton,
  OfferTypesAndMealType,
} from './fdr-offer-item.styles';

export const FdrOfferItem: React.FC<IFdrOfferItemProps> = ({
  price,
  link,
  disrupter,
  duration,
  productFeatures,
  productTravelType,
  departureDate,
  returnDate,
  breadcrumbs,
  fromTo,
  distanceInfo,
  className,
  openMethod,
  mealType,
  productType,
  onClose,
  transferPrice,
  transferIncluded,
  travelType,
  onClick,
  image,
  testId,
  rating,
  title,
  ta,
}) => {
  const [t] = useTranslation(['results', 'common']);
  const { mobile } = useDeviceType();

  const handleClose = useMemo(
    () =>
      onClose
        ? (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            onClose();
          }
        : undefined,
    [onClose]
  );

  return (
    <FdrProductCard
      distanceInfo={distanceInfo}
      image={image}
      productFeatures={productFeatures}
      productType={productType}
      disrupter={disrupter}
      link={link}
      title={title}
      openMethod={openMethod}
      price={price}
      ta={ta}
      rating={rating}
      subTitle={<FdrOfferBreadcrumbs breadcrumbs={breadcrumbs} />}
      productTravelType={productTravelType}
      testId={testId}
      className={className}
      closeButton={
        handleClose && mobile ? (
          <FdrOfferCloseButton
            variant={E_BUTTON_TYPE.ICON_BUTTON}
            icon={{ name: 'close', size: 'xs' }}
            onClick={handleClose}
            className="offer-close-btn"
          />
        ) : null
      }
      transferPrice={transferPrice}
      transferIncluded={transferIncluded}
      travelType={travelType}
      onClick={onClick}
    >
      {departureDate && returnDate && duration ? (
        <FdrOfferDatesInformation
          departureDate={departureDate}
          returnDate={returnDate}
          duration={duration}
        />
      ) : null}
      {fromTo ? <FdrFromTo>{fromTo}</FdrFromTo> : null}
      <OfferTypesAndMealType>
        {productTravelType?.map((item, i) => {
          return (
            <React.Fragment key={i}>
              {t(item.title)}
              {productTravelType.length - 1 !== i && <span> + </span>}
            </React.Fragment>
          );
        })}
        {mealType ? <span>, {mealType}</span> : null}
      </OfferTypesAndMealType>
    </FdrProductCard>
  );
};
