import {
  query2FdrCriteria,
  TFdrMediaMeta,
  TFdrMediaMetaMap,
} from '@hotelplan/fdr.regular.basis.fdr-picture';
import { lCfg, pCfg } from 'fdr/components/util/criterias/base.dmc';
import { FdrImageResizeMode } from '@hotelplan/supergraph-api';

export const fdrSrlProductImageMobileImagesSpecs: Array<
  [string, TFdrMediaMeta]
> = [
  [`364w_mobile`, pCfg(364, 220, FdrImageResizeMode.Fill)],
  [`728w_mobile`, pCfg(728, 294, FdrImageResizeMode.Fill)],
];

const fdrSrlProductImageDesktopImagesSpecs: Array<[string, TFdrMediaMeta]> = [
  [`640w_desktop`, lCfg(640, 430, FdrImageResizeMode.Fill)],
  [`1280w_desktop`, lCfg(1280, 860, FdrImageResizeMode.Fill)],
];

export const fdrSrlProductImageImagesSpecs: TFdrMediaMetaMap = new Map([
  ...fdrSrlProductImageMobileImagesSpecs,
  ...fdrSrlProductImageDesktopImagesSpecs,
]);

export const fdrSrlProductImageCriteria = query2FdrCriteria(
  fdrSrlProductImageImagesSpecs
);
